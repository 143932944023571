import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { InserzioniFarmacie_Head } from './InserzioniFarmacie_Head';
import { InserzioniFarmacie_Lista }  from './InserzioniFarmacie_lista';
import { Home_Rg1 } from './Home_Rg1';

export const InserzioniFarmacie = () => {

  return (
    <>
      <Navbar1 />
      <InserzioniFarmacie_Head />
      <Home_Rg1 />
      <InserzioniFarmacie_Lista />
    </>
  )
}