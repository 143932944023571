import React, { useEffect, useState } from "react"
import { InserzioniFarmacie_Sb } from './inserzioni_farmacie_sb';
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link} from "react-router-dom";


export const InserzioniFarmacie_Lista = () => {

  const { regione } = useParams();
  var pagina = "";
  var titolo = "";

  if (titolo == "") {
    pagina = "/farmacia-in-vendita/";
    titolo = "Inserzioni farmacie in vendita in regione " + regione;
  }
   if (regione == "Ricerche") {
    pagina = "/RicercaFarmacia/";
    titolo = "Inserzioni di ricerca farmacie in vendita";
   }
  if (regione == "Vendita") {
    pagina = "/farmacia-in-vendita/";
    // titolo = "Inserzioni farmacie in vendita in regione " + regione;
    titolo = "Inserzioni farmacie in vendita";
  }

  let api_url = "https://org.farmacontatto.it/PhsCrm/inserzioni/esegui/get_inserzioni_web.php?r=" + regione + "&t=FC";

  // alert(regione);

  const [DatiJson, setFarma] = useState([])
  let [isLoaded, setIsLoaded] = useState(false);
  let [err, setErr] = useState(null);

  useEffect(() => {
    const getFarma = () => {
      fetch(api_url)
        .then(response => {
          if (response.status >= 400) {
            throw new Error("Si è verificato un errore")
          }
          return response.json()
        })
        .then(DatiJson => {
          setFarma(DatiJson)
          setIsLoaded(true)
        },
          err => {
            setErr(err)
            setIsLoaded(true)
          })
    };
    getFarma()
  }, [])

  if (err) {
    return <div> {err.message} </div>
  } else if (!isLoaded) {
    return <div> Loading... </div>
  } else {

    return (
      <>

        <HelmetProvider>
          <Helmet>
            <title>Inserzioni ricerca farmacie in vendita e annunci farmacie in vendita - Farmacontatto</title>
            <meta name="description" content={'Cerchi una farmacia in vendita in regione ' + regione + '? Ne hai una da vendere o da far valutare? ▷ consulta Farmacontatto.it e contattaci riservatamente.'} />
            <link rel="canonical" href={"https://org.farmacontatto.it/InserzioniFarmacie/"  + regione + '/'}/>
          </Helmet>
        </HelmetProvider>

        {regione == 'Vendita' &&
          <HelmetProvider>
            <Helmet>
              <title>Inserzioni ricerca farmacie in vendita e annunci farmacie in vendita - Farmacontatto</title>
              <meta name="description" content={'Cerchi una farmacia in vendita? Oppure ne hai una da vendere o da far valutare? ▷ consulta Farmacontatto.it e contattaci riservatamente.'} />
              <link rel="canonical" href={"https://org.farmacontatto.it/InserzioniFarmacie/"  + regione + '/'}/>
            </Helmet>
          </HelmetProvider>
        }

        {regione == 'Ricerche' &&
          <HelmetProvider>
            <Helmet>
              <title>Inserzioni ricerca farmacie in vendita e annunci farmacie in vendita - Farmacontatto</title>
              <meta name="description" content={'Cerchi una farmacia in vendita? Oppure ne hai una da vendere o da far valutare? ▷ consulta Farmacontatto.it e contattaci riservatamente.'} />
              <link rel="canonical" href={"https://org.farmacontatto.it/InserzioniFarmacie/"  + regione + '/'}/>
            </Helmet>
          </HelmetProvider>
        }

        <div className='my-div'>
          <div className="row g-2">
            <div className="col-md-9">
              <article className="blog-post p-4"
                style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>

                <h1 className="blog-post-meta">
                  {titolo}
                </h1>
                <div className="row flex-md-row pt-3">
                  {DatiJson.length > 0 ?
                    <div className="col-md-12">

                      {DatiJson.map(farma => (
                        <div className="row mb-2">
                          <div className="col-md-12">

                            {farma.tipo_inserzione == '1' &&
                              <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                <div className="d-none d-lg-block">
                                  <Link to={'/farmacia-in-vendita/' + farma.url_pagina}>
                                    <img className='rounded-circle m-3' src={"../../assets/img/Farmacie-in-vendita-" + farma.immagine + '.jpg'} alt={farma.title_immagine} title={farma.title_immagine} width="100" height="100" loading="lazy" />
                                    <br></br>{farma.regione}</Link>
                                </div>
                                <div className="card-body d-flex flex-column align-items-start">
                                  <div className="container">
                                    <div className="row justify-content-between">
                                      <div className={"col-xl-4 col-md-6 col-sm-8 my-trattativa-" + farma.colore_stato_trattative}>{farma.stato_trattative}
                                      </div>
                                      <div className={"col-xl-2 col-md-3 col-sm-4 my-text-dark"}>Rif: {farma.rif_farm}
                                      </div>
                                    </div>
                                  </div>
                                  <Link to={'/farmacia-in-vendita/' + farma.url_pagina}>
                                    <h2 className="my-text-dark mt-2" href="#">{farma.titolo_inserzione}
                                    </h2>
                                  </Link>
                                  <p className="my-text-dark text-start mb-3">{farma.riassunto_inserzione}</p>
                                  <button type="button" className="btn btn-warning btn-md">
                                    <Link className='btn-href my-text-dark' to={"/farmacia-in-vendita/" + farma.url_pagina}>Scopri di più</Link>
                                  </button>
                                </div>
                              </div>
                            }

                            {farma.tipo_inserzione == '2' &&
                              <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                <div className="d-none d-lg-block">
                                <Link to={'/farmacia-in-vendita/' + farma.url_pagina}>
                                    <img className='rounded-circle m-3' src={"../../assets/img/Farmacie-in-vendita-" + farma.immagine + '.jpg'} alt={farma.title_immagine} title={farma.title_immagine} width="100" height="100" loading="lazy" />
                                    <br></br>{farma.regione}</Link>
                                </div>
                                <div className="card-body d-flex flex-column align-items-start">
                                  <div className="container">
                                    <div className="row justify-content-between">
                                      <div className={"col-xl-4 col-md-6 col-sm-8 my-trattativa-" + farma.colore_stato_trattative}>{farma.stato_trattative}
                                      </div>
                                      <div className={"col-xl-2 col-md-3 col-sm-4 my-text-dark"}>Rif: {farma.rif_farm}
                                      </div>
                                    </div>
                                  </div>
                                  <Link to={'/farmacia-in-vendita/' + farma.url_pagina}>
                                    <h2 className="my-text-dark mt-2" href="#">{farma.titolo_inserzione}
                                    </h2>
                                  </Link>
                                  <p className="my-text-dark text-start mb-3">{farma.riassunto_inserzione}</p>
                                  <button type="button" className="btn btn-warning btn-md">
                                {/*    <Link className='btn-href my-text-dark' to={"/RicercaFarmacia/" + farma.url_pagina}>Scopri di più</Link> */}
                                    <Link className='btn-href my-text-dark' to={"/farmacia-in-vendita/" + farma.url_pagina}>Scopri di più</Link>
                                  </button>
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      ))}
                    </div>
                    : <div> Inserzioni non trovate! </div>}
                  <div className="col-12" id='Comprare una farmacia'>
                  </div>
                </div>
              </article>
            </div>
            < InserzioniFarmacie_Sb />
          </div>
        </div>
      </>
    );
  }
}

export default InserzioniFarmacie_Lista;